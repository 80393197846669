import { useEffect, useState } from "react";

function App() {
  const [birthData, setBirthData] = useState();

  const [myAge, setMyAge] = useState();

  const [isReady, setIsReady] = useState(false); //계산전 나이 문구 안보여주는 기능구현용 상태

  const today = new Date();

  ///useState로 관리하여 특정시점에 값이 선언되도록 함

  // const birthDate = new Date({birthData}); // ->
  // let age = today.getFullYear() - birthDate.getFullYear();
  // const m = today.getMonth() - birthDate.getMonth();

  const [birthDate, setBirthDate] = useState(new Date("1992/01/12"));
  // {console.log(birthDate)}
  const [age, setAge] = useState();
  const [m, setM] = useState();


  const [isValidated, setIsValidated] = useState(false);

  

  const checkInputCond = (e)=>{
    var regExp = /\d{8}/
    // console.log('생년월일 유효성 검사::', regExp.test(e.target.value))
    regExp.test(e.target.value) === true? setIsValidated(true) : setIsValidated(false)
    // console.log(isValidated)
  } 

  useEffect(() => {
    setBirthDate(new Date(birthData));
    console.log(birthData);
  }, [birthData]);

  useEffect(() => {
    setAge(today.getFullYear() - birthDate.getFullYear());
    setM(today.getMonth() - birthDate.getMonth());
  }, [birthDate]);

  useEffect(()=>{
    setIsReady(false)
  },[isValidated])

  const handleSetMyAge = () => {
    if(isValidated===true) 
    {
      m < 0 || (m === 0 && today.getDate() < birthDate.getDate())
        ? setMyAge(age - 1)
        : setMyAge(age);
    setIsReady(true);
    }
    
  };

  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="Header" style={{ width: "100%", height: "150px" }}>
        {" "}
      </div>
      <div className="Title">
        <h1> 만 나이 계산기 </h1>
      </div>
      <div className="input_birthDate">
        <div className="input_button">
          <input
           maxLength='8'
            style={{ height: "18px", paddingTop: "2px" }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // console.log(parseInt(e.target.value))
                
                handleSetMyAge();
                // console.log(`birthData:${birthData}               birthDate:${birthDate}
                // age:${age}    myage:${myAge}
                // m:${m}
                // `)
              }
            }}
            onChange={(e) => {
              checkInputCond(e)
              setBirthData(
                `${String(e.target.value).substr(0, 4)}/${String(
                  e.target.value
                ).substr(4, 2)}/${String(e.target.value).substr(6, 2)}`
              );

              // setMyAge(today.getFullYear() - )
            }}
          ></input>
          <button
            style={{ height: "25px" }}
            onClick={() => {
              handleSetMyAge();
            }}
          >
            내 나이 확인
          </button>
        </div>
        <div
          className="input_example"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {!isValidated?<div
            style={{ color: "red" }}
          >{`생년월일 8자리를 모두 입력해주세요.`}</div>:''}
          <div
            style={{ color: "grey" }}
          >{`ex1) 1992년 1월 12일 경우, 19920112`}</div>
          <div
            style={{ color: "grey" }}
          >{`ex2) 2002년 1월 12일 경우, 20020112`}</div>
        </div>
      </div>
      <div className="myAgeResult">
        {isReady &isValidated?  (
          <>
            <h2> 내 나이는 {myAge}살 입니다</h2>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default App;
